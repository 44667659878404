// import { useFormik } from 'formik'
import React, { FC, useEffect, useState } from 'react'
import { Button, Modal, Upload } from 'antd'
import type { RcFile, UploadFile, UploadProps } from 'antd/es/upload/interface'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { requestUser } from '../../store/user/user.actions'
import api from '../../helpers/api'
import { APIResponse } from '../../@types/api'
import { requestDepositTo } from '../../store/deposit/deposit.actions'

interface UploadSlipFormProps {
	bankNumber: string
	onClose: () => void
}

const UploadSlipForm: FC<UploadSlipFormProps> = ({ bankNumber, onClose }) => {
	const [fileList, setFileList] = useState<UploadFile[]>([]);
	const [previewVisible, setPreviewVisible] = useState(false)
	const [previewImage, setPreviewImage] = useState('')
	const [previewTitle, setPreviewTitle] = useState('')
	const [uploading, setUploading] = useState(false)
	const dispatch = useDispatch()

	useEffect(() => {
		dispatch(requestDepositTo())
	}, [dispatch])

	const getBase64 = (file: RcFile): Promise<string> =>
		new Promise((resolve, reject) => {
			const reader = new FileReader()
			reader.readAsDataURL(file)
			reader.onload = () => resolve(reader.result as string)
			reader.onerror = error => reject(error)
		})

	const handleCancel = () => setPreviewVisible(false)

	const handlePreview = async (file: UploadFile) => {
		if (!file.url && !file.preview) {
			file.preview = await getBase64(file.originFileObj as RcFile)
		}

		setPreviewImage(file.url || (file.preview as string))
		setPreviewVisible(true)
		setPreviewTitle(file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1))
	}

	const handleChange: UploadProps['onChange'] = ({ fileList: newFileList }) => setFileList(newFileList as any)

	const beforeUploadPicture = (file: any) => {
		const isJpg = file.type === 'image/jpeg'
		const isPng = file.type === 'image/png'


		const isLt2M = file.size / 1024 / 1024 < 2

		if (!isJpg && !isPng) {
			toast.error('คุณสามารถอัปโหลดไฟล์ JPG เท่านั้น!')
			return Upload.LIST_IGNORE
		}

		if (!isLt2M) {
			toast.error('รูปภาพต้องมีขนาดเล็กกว่า 2MB!')
			return Upload.LIST_IGNORE
		}

		return false;
	}

	const handleUpload = async () => {
		const formData = new FormData()

		formData.append('file', fileList[0]?.originFileObj as RcFile)
		formData.append('bankNumber', bankNumber)
		setUploading(true)

		if (fileList.length > 0 && formData.get('bankNumber')) {
			try {
				const res = await api.post<void, APIResponse>('/deposit/scan-qrcode', formData)

				dispatch(requestUser())
				setFileList([])
				toast.success(res.message)
				onClose()
			} catch ({ message }) {
				setFileList([])
				toast.error(message as string)
			} finally {
				setUploading(false)
			}
		} else {
			setUploading(false)
			toast.error('กรุณาใส่ข้อมูลให้ครบถ้วน')
		}
	}

	const uploadButton = (
		<>
			<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="-0.5 -0.5 16 16" id="Bill-Check--Streamline-Solar" height="32" width="32"><desc>Bill Check Streamline Icon: https://streamlinehq.com</desc><g id="Line Duotone/Money/Bill Check"><path id="Vector" stroke="#fff" d="M10.471874999999999 1.25H4.5281375c-0.7242999999999999 0 -1.08644375 0 -1.37851875 0.10163125 -0.55385625 0.19271875 -0.9886937499999999 0.6403625000000001 -1.1759 1.21053125C1.875 2.8628437499999997 1.875 3.2356562500000003 1.875 3.9812874999999996V12.733875c0 0.5363749999999999 0.615625 0.8210000000000001 1.0050625 0.46462499999999995 0.22879375000000002 -0.209375 0.57358125 -0.209375 0.8023750000000001 0l0.30193749999999997 0.2763125c0.40099375 0.36693749999999997 1.00525625 0.36693749999999997 1.40625 0 0.40099375 -0.36693749999999997 1.00525 -0.36693749999999997 1.40625 0s1.00525 0.36693749999999997 1.40625 0 1.00525 -0.36693749999999997 1.40625 0 1.00525 0.36693749999999997 1.40625 0l0.30193749999999997 -0.2763125c0.22881249999999997 -0.209375 0.5735625 -0.209375 0.8023750000000001 0 0.3894375 0.356375 1.0050625 0.07175 1.0050625 -0.46462499999999995V3.9812874999999996c0 -0.74563125 0 -1.11844375 -0.09875 -1.419125 -0.1871875 -0.57016875 -0.622 -1.0178125 -1.175875 -1.21053125C11.558312500000001 1.25 11.1961875 1.25 10.471874999999999 1.25Z" stroke-width="1"></path><path id="Vector_2" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" d="m5.9375 6.5 0.8928750000000001 1L9.0625 5" stroke-width="1"></path><path id="Vector_3" stroke="#fff" stroke-linecap="round" d="M4.6875 9.6875h5.625" stroke-width="1"></path></g></svg>
			<div style={{ marginTop: 8 }}>คลิกเพื่ออัปโหลดสลิป</div>
		</>
	)

	return (
		<>
			<Upload
				className="ant-upload-drag-custom"
				listType="picture-card"
				fileList={fileList}
				onPreview={handlePreview}
				onChange={handleChange}
				beforeUpload={beforeUploadPicture}
			>
				{fileList.length >= 1 ? null : uploadButton}
			</Upload>
			<div><small>*อัปโหลดสลิปแล้ว กดปุ่มยืนยัน เพื่ออัปเดตยอดทันที</small></div>
			<Button
				type="primary"
				htmlType="submit"
				loading={uploading}
				onClick={handleUpload}
				style={{ marginTop: 16 }}
				block
			>
				ยืนยันสลิป
			</Button>
			<Modal open={previewVisible} title={previewTitle} footer={null} onCancel={handleCancel} wrapClassName='modal-img' zIndex={10001} centered>
				<img alt={previewTitle} src={previewImage} style={{ width: '100%' }} />
			</Modal>
		</>
	)
}

export default UploadSlipForm
